import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useBrands } from '../../hooks/useBrands';

interface Car {
  id: string;
  make: string;
  model: string;
  year: number; // Ensure `year` is always required
  brand: string;
  features: string[];
  created_at: string;
  updated_at: string;
}

interface CarFormFeature {
  name: string;
  available: boolean;
}

interface CarFormData extends Omit<Car, 'id' | 'created_at' | 'updated_at' | 'brand' | 'features'> {
  year: number; // Make `year` a required number
  features?: CarFormFeature[];
}

const initialFormData: CarFormData = {
  make: '',
  model: '',
  year: new Date().getFullYear(), // Ensure default year is always defined
  features: [],
};

const DEFAULT_FEATURES: CarFormFeature[] = [
  { name: 'Air Conditioning', available: false },
  { name: 'Sunroof', available: false },
  { name: 'Heated Seats', available: false },
  { name: 'Navigation System', available: false },
];

export default function CarForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: brands, isLoading: brandsLoading } = useBrands();
  const [formData, setFormData] = useState<CarFormData>(initialFormData);
  const [features, setFeatures] = useState<CarFormFeature[]>(DEFAULT_FEATURES);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async () => {
    setIsSubmitting(true);
    try {
      // Add form submission logic
      console.log('Form submitted', formData);
      navigate('/admin/dashboard/cars');
    } catch (err) {
      setError('Failed to submit the form');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h1>Car Form</h1>
      <form onSubmit={e => e.preventDefault()}>
        {/* Add input fields here */}
        <button
          type="button"
          onClick={handleFormSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      {error && <p className="error">{error}</p>}
    </div>
  );
}
